<template>

  <nav class="navbar navbar-expand-lg navbar-dark navbar-stick-dark">
		
		<div class="container">
			
			<div class="navbar-left mr-auto">
				<button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarToggleContent" 
								aria-controls="navbarToggleContent" aria-expanded="false" aria-label="Toggle navigation">
					☰
				</button>
				<a class="navbar-brand" href="https://ksrservices.co/">
					<img class="logo-dark" src="/images/logo.png" alt="KSR Services" />
				</a>

				<span class="navbar-divider d-none d-lg-block"></span>
			</div>

			<section class="navbar-mobile navbar-collapse collapse" id="navbarToggleContent" aria-expanded="true">

				<ul id="menu-primary-navbar" class="nav nav-navbar mr-auto">

					<li id="nav-menu-item-1" class="nav-item">
						<a class="nav-link  menu-item-type-post_type menu-item-object-page" href="#header">
							{{ $t('menu.welcome') }}
						</a>
					</li>

					<li id="nav-menu-item-2" class="nav-item">
						<a class="nav-link  menu-item-type-custom menu-item-object-custom" href="#who-we-are">
							{{ $t('menu.who-we-are') }}
						</a>
					</li>

					<li id="nav-menu-item-3" class="nav-item">
						<a class="nav-link  menu-item-type-custom menu-item-object-custom" href="#our-services">
							{{ $t('menu.services') }}
						</a>
					</li>

					<li id="nav-menu-item-4" class="nav-item">
						<a class="nav-link  menu-item-type-custom menu-item-object-custom" href="#our-clients">
							{{ $t('menu.customers') }}
						</a>
					</li>

				</ul>

				<div class="navbar_buttons pe-2 locale-changer">
					<select class="form-select" v-model="$i18n.locale">
						<option v-for="locale in $i18n.availableLocales" :key="`locale-${locale}`" :value="locale">{{ locale }}</option>
					</select>
				</div>

				<div class="navbar_buttons pe-2 dropdown d-none">
					<button class="btn btn-primary dropdown-toggle" type="button" id="dropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
						Language
					</button>
					<ul class="dropdown-menu" aria-labelledby="dropdownMenuButton">
						<li><a class="dropdown-item" href="/en/">English</a></li>
						<li><a class="dropdown-item" href="/fr/">Français</a></li>
					</ul>
				</div>

				<div class="navbar_buttons">
					<a class="btn btn-primary btn-xl btn-round" href="#rendez-vous"> 
						{{ $t('menu.contact-us') }}
					</a>
				</div>

			</section>

		</div>

	</nav>

	<!-- Main Content -->
	<main class="main-content">

		<!-- header -->
		<header id="header" class="header h-fullscreen" style="background-color: #e6eefb; background-image: url(/images/sliders/1.jpg);">
			<!--  -->

			<div class="overlay opacity-95" style="background-image: linear-gradient(135deg, #fff 0%, #fff 40%, transparent 100%);"></div>

			<div class="container h-100">

				<div class="row align-items-center h-100">
					
					<div class="col-lg-5">
						
						<h1 class="animate__animated animate__fadeInDown">{{ $t('header.title') }}</h1>
						<p class="mt-5 animate__animated animate__fadeInLeftBig">
							<span class="lead">{{ $t('header.desription') }}</span>
							<br>{{ $t('header.sub-desription') }}
						</p>
						<p class="gap-xy pt-3 animate__animated animate__fadeInUpBig">
							<a class="btn btn-outline-primary btn-xl btn-round min-width-170" href="#our-services">
								{{ $t('header.button') }}
							</a>
						</p>

					</div>

					<div class="col-lg-6 ml-auto d-none">
					</div>

				</div>

			</div>

		</header>

		<!-- who we are -->
		<section id="who-we-are" class="section bg-lightest angled lower-end">

			<div class="container py-90" v-auto-animate>

				<header class="section-header" data-aos="zoom-in">
					<h2><i class="icon-megaphone boxed-icon fw-bold"></i></h2>
					<h3>{{ $t('menu.who-we-are') }}</h3>
				</header>

				<div class="row align-items-center">

					<div class="col-md-5 mx-auto" data-aos="fade-right">

						<p class="fw-bold fs-19">
							{{ $t('who-we-are.title') }}
						</p>
						<p>
							{{ $t('who-we-are.paragraphe-1') }}
						</p>
						<p>
							{{ $t('who-we-are.paragraphe-2') }}
						</p>
						<p>
							{{ $t('who-we-are.paragraphe-3') }}
						</p>
						<a href="#" class="d-none">
							En savoir plus <i class="ti-angle-right fs-10 ml-1"></i>
						</a>

					</div>

					<div class="col-md-6 mx-auto text-center" data-aos="fade-left">
						<img src="/images/who-we-are.png" alt="who we are" class="border-radius-15"/>
					</div>

				</div>
			</div>

		</section>

		<!-- our services -->
		<section id="our-services" class="section bg-light angled lower-end upper-end">

			<div class="container py-90">

				<header class="section-header" data-aos="zoom-in">
					<h2><i class="icon-genius boxed-icon fw-bold"></i></h2>
					<h2>{{ $t('menu.services') }}</h2>
				</header>

				<div class="row">
					
					<!-- custom-software -->
					<div class="col-md-6" data-aos="fade-right">
						<div class="section-box">
							<div class="d-flex align-items-center">
								<img src="/images/our-services/codage-web.png" alt="Développement Web" class="width-11" />
								<h4 class="mleft">{{ $t('services.custom-software.title') }}</h4>
							</div>
							<p class="pt-3">
								{{ $t('services.custom-software.paragraphe-1') }}
							</p>
							<ul>
								<li> <span class="fw-900">{{ $t('services.custom-software.list.item1.title') }}</span> : {{ $t('services.custom-software.list.item1.desription') }}</li>
								<li> <span class="fw-900">{{ $t('services.custom-software.list.item2.title') }}</span> : {{ $t('services.custom-software.list.item2.desription') }}</li>
								<li> <span class="fw-900">{{ $t('services.custom-software.list.item3.title') }}</span> : {{ $t('services.custom-software.list.item3.desription') }}</li>
							</ul>
							<p class="">
								{{ $t('services.custom-software.paragraphe-2') }}
							</p>
						</div>
					</div>

					<!-- data-migration -->
					<div class="col-md-6" data-aos="fade-left">
						<div class="section-box">
							<div class="d-flex align-items-center">
								<img src="/images/our-services/migration.png" alt="Migration de données" class="width-10" />
								<h4 class="mleft">{{ $t('services.data-migration.title') }}</h4>
							</div>
							<p class="pt-3">
								{{ $t('services.data-migration.paragraphe-1') }}
							</p>
							<p class="">
								{{ $t('services.data-migration.paragraphe-2') }}
							</p>
							<ul>
								<li> <span class="fw-900">{{ $t('services.data-migration.list.item1.title') }}</span> : {{ $t('services.data-migration.list.item1.desription') }}</li>
								<li> <span class="fw-900">{{ $t('services.data-migration.list.item2.title') }}</span> : {{ $t('services.data-migration.list.item2.desription') }}</li>
							</ul>
							<p class="">
								{{ $t('services.data-migration.paragraphe-3') }}
							</p>
						</div>
					</div>

					<!-- design -->
					<div class="col-md-6" data-aos="fade-right">
						<div class="section-box">
							<div class="d-flex align-items-center">
								<img src="/images/our-services/ui.png" alt=" UX/UI design" class="width-10" />
								<h4 class="mleft">{{ $t('services.design.title') }}</h4>
							</div>
							<p class="pt-3">
								{{ $t('services.design.paragraphe-1') }}
							</p>
							<p class="">
								{{ $t('services.design.paragraphe-2') }}
							</p>
							<ul>
								<li> <span class="fw-900">{{ $t('services.design.list.item1.title') }}</span> : {{ $t('services.design.list.item1.desription') }}</li>
								<li> <span class="fw-900">{{ $t('services.design.list.item2.title') }}</span> : {{ $t('services.design.list.item2.desription') }}</li>
								<li> <span class="fw-900">{{ $t('services.design.list.item3.title') }}</span> : {{ $t('services.design.list.item3.desription') }}</li>
							</ul>
						</div>
					</div>

					<!-- maintenance -->
					<div class="col-md-6" data-aos="fade-left">
						<div class="section-box">
							<div class="d-flex align-items-center">
								<img src="/images/our-services/entretien.png" alt="Maintenance logicielle" class="width-10" />
								<h4 class="mleft">{{ $t('services.maintenance.title') }}</h4>
							</div>
							<p class="pt-3">
								{{ $t('services.maintenance.paragraphe-1') }}
							</p>
							<p class="">
								{{ $t('services.maintenance.paragraphe-2') }}
							</p>
							<ul>
								<li> <span class="fw-900">{{ $t('services.maintenance.list.item1.title') }}</span> : {{ $t('services.maintenance.list.item1.desription') }}</li>
								<li> <span class="fw-900">{{ $t('services.maintenance.list.item2.title') }}</span> : {{ $t('services.maintenance.list.item2.desription') }}</li>
								<li> <span class="fw-900">{{ $t('services.maintenance.list.item3.title') }}</span> : {{ $t('services.maintenance.list.item3.desription') }}</li>
							</ul>
							<p class="">
								{{ $t('services.maintenance.paragraphe-3') }}
							</p>
						</div>
					</div>

				</div>
				
			</div>

		</section>

		<!-- our clients -->
		<section id="our-clients" class="section">

			<div class="container py-90">

				<header class="section-header" data-aos="zoom-in">
					<h2><i class="icon-trophy boxed-icon fw-bold"></i></h2>
					<h2>{{ $t('menu.customers') }}</h2>
					<hr>
					<p class="lead">
						{{ $t('customers.title') }}
					</p>
					<p class="">
						{{ $t('customers.description') }}
					</p>
				</header>

				<div class="row gap-y py-70">

					<div class="d-flex align-items-center justify-content-center mh-80" v-auto-animate>
						<Transition name="slide" v-for="slide in visible_slides" :key="slide">
							<div class="slide d-flex align-items-center justify-content-center" v-if="slide.show" >
								<a class="" :href="slide.link" target="_blank">
									<img :src="slide.source" :title="slide.title" :class="slide.class"/>
								</a>
							</div>
						</Transition>
					</div>
					
				</div>

			</div>

		</section>

		<!-- rendez-vous-->
		<section id="rendez-vous" class="section bg-light angled upper-end ">

			<div class="container py-70">

				<header class="section-header" data-aos="zoom-in">
					<h2><i class="icon-calendar boxed-icon fw-bold"></i></h2>
					<h3>{{ $t('get-in-touch.title') }}</h3>
				</header>

				<div class="row text-center py-30">

					<div class="col-md-5 mx-auto text-center" data-aos="fade-right">
						<img src="/images/rendez-vous.jpg" alt="rendez-vous" class="border-radius-5"/>
					</div>
					
					<div class="col-md-5 mx-auto" data-aos="fade-left">
						<br>
						<p class="fs-17">
							{{ $t('get-in-touch.paragraphe-1') }}
						</p>
						<p>
							{{ $t('get-in-touch.paragraphe-2') }}
						</p>
						<br>
						<p class="gap-xy pt-3 animate__animated animate__fadeInUpBig">
							<a class="btn btn-outline-primary btn-xl btn-round min-width-170" 
									href="https://calendly.com/rkissi/60min?back=1" target="_blank">
								Être rappelé
							</a>
						</p>
					</div>

				</div>
			</div>

		</section>

		<!-- form -->
		<section class="section d-none">

			<div class="container py-100">

				<h2 class="text-center fw-200">Join KSR Services</h2>
				<form class="row gap-y mt-7" method="GET">
					<div class="col-md-6 col-lg-3">
						<input class="form-control" type="text" name="name" placeholder="Name">
					</div>

					<div class="col-md-6 col-lg-3">
						<input class="form-control" type="email" name="email" placeholder="Email">
					</div>

					<div class="col-md-6 col-lg-3">
						<input class="form-control" type="password" name="password" placeholder="Password">
					</div>

					<div class="col-md-6 col-lg-3">
						<button class="btn btn-success btn-md btn-block"> Get Started </button>
					</div>
				</form>
			</div>

		</section>

	</main>
	<!-- /.main-content -->

	<footer class="footer bg-lightest">

		<div class="container">
			
			<div class="row align-items-center">
				
				<div class="col-md-4">
					<img class="width-60 my-3" src="/images/logo.png" alt="KSR Services" />
					<div class="ms-4">
						<p class=""> © KSR Services 2022 </p>
						<div class="social social-sm social-hover-bg-brand">
							<a class="social-linkedin" href="https://fr.linkedin.com/company/ksr-services-co?original_referer=https://ksrservices.co/" target="_blank">
								<i class="icon-linkedin"></i>
							</a>
							<a class="social-facebook" href="https://www.facebook.com/KSR-services-102087375054921" target="_blank">
								<i class="icon-facebook"></i>
							</a>
						</div>
					</div>
				</div>

				<div class="col-md-4">
					<div class="nav nav-bold nav-uppercase nav-center d-none">
						<a class="nav-link" href="#">link</a>
					</div>
				</div>

				<div class="col-md-4">
					<ul class="style-none fs-15">
						<li class="mbottom-10">
							<i class="icon-map-pin"></i> 
							<a class="ms-2" 
								href="https://goo.gl/maps/yJkhDNsNycakg1Nz9"
								target="_blank">
								57 Rue Amsterdam, 75008 PARIS
							</a>
						</li>
						<li class="mbottom-10">
							<i class="icon-envelope"></i> 
							<a class="ms-1" href="mailto:contact@ksrservices.co">contact@ksrservices.co</a>
						</li>
						<li class="">
							<i class="icon-phone"></i> 
							<a class="ms-2" href="tel:+33766023140">+33 6 50 31 29 32</a>
						</li>
					</ul>
				</div>

			</div>

			<div class="row py-3 align-items-center d-none">
				
				<div class="col-md-4 d-flex float-start align-items-center">
					
				</div>

				<div class="col-md-4">
					
				</div>

				<div class="col-md-4 text-center text-md-right">
					
				</div>

			</div>

		</div>

	</footer>

</template>

<script>

import AOS from "aos";

export default {

  name: 'Home',

  components: {
    
  },

  data () {
    return {
      visible_slide_index : 0,
      visible_slides : [],
      visible_slides_size : 0,
      slides : [
        {
          link : "https://www.japautoauto.com/",
          source : "/images/clients/japauto.png",
          title : "japauto",
          class : "width-60 mx-auto",
          show : true
        },
        {
          link : "https://www.barenstark.com/",
          source : "/images/clients/barenstark.png",
          title : "barenstark",
          class : "width-80 mx-auto",
          show : true
        },
        {
          link : "https://www.elyseemotor.com/",
          source : "/images/clients/elysee-motor.png",
          title : "Elysee Motor",
          class : "width-50 mx-auto",
          show : true
        },
        {
          link : "https://www.kia-idfa.com/",
          source : "/images/clients/kia-idfa.png",
          title : "kia-idfa",
          class : "width-50 mx-auto",
          show : true
        },
        {
          link : "https://www.patrickpons.com/",
          source : "/images/clients/patrick-pons.png",
          title : "patrick-pons",
          class : "width-50 mx-auto",
          show : true
        },
        {
          link : "https://www.zebecane.fr/",
          source : "/images/clients/zebecane.png",
          title : "zebecane",
          class : "width-60 mx-auto",
          show : true
        },
        {
          link : "https://www.scor.com",
          source : "/images/clients/scor.png",
          title : "scor",
          class : "width-60 mx-auto",
          show : true
        },
        {
          link : "https://www.abeille-assurances.fr",
          source : "/images/clients/abeille-assurance.png",
          title : "aviva",
          class : "width-50 mx-auto",
          show : true
        }
      ]
    }
  },
	
  mounted() {

		AOS.init();

    this.visible_slides_size = this.initVisibleSlidesSize();

    for (let i = 0; i < this.visible_slides_size; i++) {
			this.visible_slides.push(this.slides[i]);
		}

    window.setInterval(() => {

			this.visible_slides.shift();

      this.visible_slide_index++;
      if(this.visible_slide_index>=this.slides.length)
        this.visible_slide_index=0;

      var index = this.visible_slide_index;
      for (let i = 1; i < this.visible_slides_size; i++) {
        index++;
        if(index>=this.slides.length)
          index=0;
      }
			this.visible_slides.push(this.slides[index]);

    }, 1500);

  },

  methods: {

    initVisibleSlidesSize(){

      // small
      if(window.matchMedia("only screen and (max-width: 480px)").matches)
        return 2;

      // tablet
      if(window.matchMedia("only screen and (max-width: 990px)").matches)
        return 3;

      // other deveices
      return 4;
    }
  
  }
}
</script>

<style scoped>

.slide {
  float: left;
  height: 100%;
	width: 284px;
	min-height: 80px;
}

.slide img {
  display: block;
}
img {
  max-width: 100%;
  height: auto;
}

.slide-enter-active,
.slide-leave-active {
  transition: opacity 0.5s ease;
}

.slide-enter-from,
.slide-leave-to {
  opacity: 0;
}
</style>

