import { createApp } from 'vue'
import { createI18n } from 'vue-i18n';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue'
import App from './App.vue';
import routes from './router/routes'

import "bootstrap/dist/js/bootstrap.min.js"
import "bootstrap/dist/js/bootstrap.esm.min.js"
import "bootstrap/dist/js/bootstrap.js"

import "@mdi/font/css/materialdesignicons.min.css"
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/css/animate.min.css";
import "./assets/css/et-line.css";
import "./assets/css/main.css";

// import AOS css style 
import 'aos/dist/aos.css'

// import translations
import en from './assets/i18n/en.json';
import fr from './assets/i18n/fr.json';

// configure i18n
const i18n = createI18n({
  locale: 'en',
  fallbackLocale: 'en',
  messages: {en, fr}
});

createApp(App)
    .use(routes)
    .use(autoAnimatePlugin)
		.use(i18n)
    .mount('#app')